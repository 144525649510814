import $ from 'jquery';
import { Controller } from 'stimulus';
import swal from '../front/sweetalert/polyfilled-sweetalert2';
export default class default_1 extends Controller {
    static targets = ['notifyReceivedButton', 'waybillRow'];
    sendReceivedNotification(event) {
        event.preventDefault();
        this.notifyReceivedButtonTarget.disabled = true;
        swal.fire({
            title: 'Siunčiami laiškai prašome palaukti...',
            didOpen() {
                swal.showLoading();
            },
        });
        $.post({
            url: this.receivedButtonPostUrl,
            data: { 'orderIds[]': this.arrivedOrderIds() },
        })
            .done((data) => {
            const stats = [];
            data.forEach((value) => (stats[value.state] = (stats[value.state] ?? 0) + 1));
            this.notifyReceivedButtonTarget.disabled = false;
            swal.hideLoading();
            swal.fire({
                icon: 'success',
                title: 'Baigta',
                html: `<strong>Informuoti sėkmingai: ${stats[202] ?? 0}</strong>\n<br>
          Jau buvo informuoti anksčiau: ${stats[208] ?? 0}\n<br>
          Nepavyko informuoti: ${stats[406] ?? 0}`,
            });
        })
            .catch(() => {
            this.notifyReceivedButtonTarget.disabled = false;
            swal.hideLoading();
            swal.fire({
                icon: 'error',
                title: 'Nepavyko išsiųsti kaikurių laiškų, bandykite dar kartą',
            });
        });
    }
    arrivedOrderIds() {
        return this.waybillRowTargets.filter((row) => row.dataset?.orderId).map((row) => Number(row.dataset.orderId));
    }
    get receivedButtonPostUrl() {
        return this.notifyReceivedButtonTarget.dataset.url;
    }
}
