import { Controller } from 'stimulus';
export default class default_1 extends Controller {
    static targets = ['addMoreButton', 'container'];
    static values = { index: Number };
    addMore(event) {
        event.preventDefault();
        this.addFormToCollection();
    }
    remove(event) {
        event.preventDefault();
        const button = event.target;
        button.closest('li')?.remove();
    }
    addFormToCollection() {
        const { prototype } = this.containerTarget.dataset;
        if (!prototype) {
            return;
        }
        const newForm = prototype
            .replace(/__name__/g, String(this.indexValue))
            .replace('form-group', 'input-group')
            .replace('/>', '/> <span class="input-group-btn"><button type="button" class="btn btn-default" data-action="form#remove">&times;</button></span>');
        this.indexValue += 1;
        const newLi = document.createElement('li');
        newLi.innerHTML = newForm;
        this.containerTarget.appendChild(newLi);
    }
}
