/* eslint-disable prettier/prettier */
import Dashboard from '@uppy/dashboard'
import findAllDOMElements from '@uppy/utils'

/**
 * Extends Uppy Dashboard to allow batch snapshots and not close all panels on file add
 */
export default class UppyBatchDashboardPlugin extends Dashboard {
  initEvents = () => {
    // Modal open button
    if (this.opts.trigger && !this.opts.inline) {
      const showModalTrigger = findAllDOMElements(this.opts.trigger)
      if (showModalTrigger) {
        showModalTrigger.forEach((trigger) => trigger.addEventListener('click', this.openModal))
      } else {
        this.uppy.log(
          'Dashboard modal trigger not found. Make sure `trigger` is set in Dashboard options, unless you are planning to call `dashboard.openModal()` method yourself',
          'warning',
        )
      }
    }

    this.startListeningToResize()
    document.addEventListener('paste', this.handlePasteOnBody)

    this.uppy.on('plugin-remove', this.removeTarget)
    this.uppy.on('file-added', (file) => {
      // ## custom change start ## don't close panels if source is webcam
      if (file.source === 'Webcam') {
        return
      }
      // ## custom change end ## don't close panels if source is webcam
      this.hideAllPanels()
    })
    this.uppy.on('dashboard:modal-closed', this.hideAllPanels)
    this.uppy.on('file-editor:complete', this.hideAllPanels)
    this.uppy.on('complete', this.handleComplete)

    // ___Why fire on capture?
    //    Because this.ifFocusedOnUppyRecently needs to change before onUpdate() fires.
    document.addEventListener('focus', this.recordIfFocusedOnUppyRecently, true)
    document.addEventListener('click', this.recordIfFocusedOnUppyRecently, true)

    if (this.opts.inline) {
      this.el.addEventListener('keydown', this.handleKeyDownInInline)
    }

    if (this.opts.autoOpenFileEditor) {
      this.uppy.on('files-added', this._openFileEditorWhenFilesAdded)
    }
  }

  // added because original #openFileEditorWhenFilesAdded is private
  _openFileEditorWhenFilesAdded = (files) => {
    const firstFile = files[0]
    if (this.canEditFile(firstFile)) {
      this.openFileEditor(firstFile)
    }
  }
}
