import { Controller } from 'stimulus';
export default class default_1 extends Controller {
    static targets = ['importFileInput'];
    connect() {
        this.importFileInputTarget.addEventListener('change', (event) => {
            ;
            event.target.form?.submit();
        });
    }
    selectFile(event) {
        event.preventDefault();
        this.importFileInputTarget.click();
    }
}
