import { Controller } from 'stimulus';
import '../../assets/styles/cart_steps.css';
export default class default_1 extends Controller {
    static targets = ['legalEntity', 'code', 'vatCode', 'nameLabel', 'nameInput', 'invoiceAddress'];
    connect() {
        this.toggleCompanyFields();
    }
    toggleCompanyFields() {
        if (this.legalEntityTarget.checked) {
            this.showCompanyFields();
        }
        else {
            this.hideCompanyFields();
        }
    }
    hideCompanyFields() {
        if (this.legalEntityTarget.checked) {
            return;
        }
        this.nameLabelTarget.innerText = this.nameInputTarget.dataset.individualPersonLabel;
        this.nameInputTarget.autocomplete = 'name';
        this.codeTarget.style.display = 'none';
        this.vatCodeTarget.style.display = 'none';
        const vatCodeInput = this.vatCodeTarget.querySelector('input');
        if (vatCodeInput) {
            vatCodeInput.required = false;
            vatCodeInput.value = '';
        }
        this.invoiceAddressTarget.style.display = 'none';
        const invoiceAddressInput = this.invoiceAddressTarget.querySelector('input');
        if (invoiceAddressInput) {
            invoiceAddressInput.required = false;
        }
        this.codeTarget.querySelector('label')?.classList.remove('required');
        const codeInput = this.codeTarget.querySelector('input');
        if (codeInput) {
            codeInput.required = false;
            codeInput.value = '';
        }
    }
    showCompanyFields() {
        if (!this.legalEntityTarget.checked) {
            return;
        }
        this.nameLabelTarget.innerText = this.nameInputTarget.dataset.legalEntityLabel;
        this.nameInputTarget.autocomplete = 'organization';
        this.codeTarget.style.display = 'block';
        this.vatCodeTarget.style.display = 'block';
        this.codeTarget.querySelector('label')?.classList.add('required');
        const codeTarget = this.codeTarget.querySelector('input');
        if (codeTarget) {
            codeTarget.required = true;
        }
        this.invoiceAddressTarget.style.display = 'block';
        const invoiceAddressLabel = this.invoiceAddressTarget.querySelector('label');
        if (invoiceAddressLabel) {
            invoiceAddressLabel.classList.add('required');
        }
        const invoiceAddressInput = this.invoiceAddressTarget.querySelector('input');
        if (invoiceAddressInput) {
            invoiceAddressInput.required = true;
        }
    }
}
