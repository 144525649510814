import { Controller } from 'stimulus';
export default class default_1 extends Controller {
    static values = {
        total: Number,
        currency: String,
        tax: Number,
        orderId: Number,
        shipping: Number,
        cartItems: Array,
        searchTerm: String,
        searchTermManufacturer: String,
    };
    connect() {
        if (this.hasCurrencyValue && this.hasTotalValue) {
            this.purchase();
        }
        if (this.hasSearchTermValue) {
            this.trackZeroSearchResults();
        }
    }
    addToCart() {
        this.fbAddToCart();
    }
    checkout() {
        this.fbInitiateCheckout();
    }
    purchase() {
        this.fbPurchase(this.totalValue, this.currencyValue);
    }
    fbPurchase(value, currency) {
        this.fbTrack('Purchase', { value, currency });
    }
    fbAddToCart() {
        this.fbTrack('AddToCart');
    }
    fbInitiateCheckout() {
        this.fbTrack('InitiateCheckout');
    }
    fbTrack(eventName, parameters) {
        try {
            if (process.env.NODE_ENV !== 'production') {
                parameters ??= {};
                parameters['test_event_code'] = 'TEST63224';
            }
            fbq('track', eventName, parameters);
        }
        catch (e) {
            console.error(e);
        }
    }
    gtmTrack(eventName, parameters) {
        try {
            window.dataLayer ??= [];
            window.dataLayer.push({ event: eventName, ...parameters });
            if (process.env.NODE_ENV !== 'production') {
                console.log('GTM track:', arguments);
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    trackZeroSearchResults() {
        if (!this.hasSearchTermValue || this.searchTermValue.length < 1) {
            return;
        }
        this.gtmTrack('zeroSearchResults', {
            searchTerm: this.searchTermValue,
            manufacturer: this.searchTermManufacturerValue,
        });
    }
}
