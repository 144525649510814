import $ from 'jquery';
import { Controller } from 'stimulus';
import { createUppyUploader } from '../Service/UppyUploader';
export default class default_1 extends Controller {
    static targets = ['uploadButtons'];
    static values = { counts: Array, countUrl: String };
    connect() {
        this.loadPartImageCounts();
    }
    async openModal(event) {
        const button = event.currentTarget;
        const { imagePostUrl, imageGetUrl, imageDeleteUrl, manufacturer = '', partNumber = '' } = button.dataset;
        if (!imagePostUrl) {
            throw 'Image POST Url not defined';
        }
        if (!imageGetUrl) {
            throw 'Image GET Url not defined';
        }
        const uppy = createUppyUploader({ endpoint: imagePostUrl });
        uppy.on('file-removed', (file, reason) => this.deleteFile(imageDeleteUrl, file, reason));
        uppy.on('complete', (result) => this.updateCounts(result, manufacturer, partNumber));
        this.loadFiles(imageGetUrl, uppy).then(() => {
            const dashboard = uppy.getPlugin('UppyBatchDashboardPlugin');
            if (!dashboard) {
                throw 'UppyBatchDashboardPlugin plugin not found';
            }
            dashboard.openModal();
        });
    }
    updateCounts({ successful }, manufacturer, partNumber) {
        if (successful.length < 1) {
            return;
        }
        this.countsValue = this.countsValue.map((value) => {
            if (value.manufacturer === manufacturer && value.partNumber === partNumber) {
                value.count += successful.length;
                return value;
            }
            return value;
        });
    }
    async deleteFile(url, file, reason) {
        if (reason !== 'removed-by-user') {
            return;
        }
        if (!file.isRemote) {
            return;
        }
        const { urlTemplate } = await import('../Utils/UrlUtil');
        $.ajax({
            method: 'DELETE',
            url: urlTemplate(url, { '{id}': String(file.meta.id) }),
        }).done(() => this.loadPartImageCounts());
    }
    countsValueChanged() {
        this.countsValue.forEach((imageCount) => {
            this.uploadButtonsTargets
                .filter((button) => {
                const { manufacturer, partNumber } = button.dataset;
                return manufacturer === imageCount.manufacturer && partNumber === imageCount.partNumber;
            })
                .forEach((button) => {
                const badge = button.querySelector('.badge');
                badge.textContent = imageCount.count > 0 ? String(imageCount.count) : '';
            });
        });
    }
    async loadFiles(url, uppy) {
        const files = await $.get(url, null, 'json');
        for (const file of files) {
            const image = await fetch(file.url);
            const imageBlob = await image.blob();
            const fileId = String(file.id);
            const fileUUID = uppy.addFile({
                id: fileId,
                name: file.filename,
                type: file.mimeType,
                data: imageBlob,
                meta: {
                    id: fileId,
                },
                source: 'Webcam',
                isRemote: true,
                preview: file.url,
            });
            uppy.setFileState(fileUUID, {
                uploadURL: file.url,
                progress: {
                    uploadComplete: true,
                    uploadStarted: Date.now(),
                    bytesUploaded: imageBlob.size,
                    bytesTotal: imageBlob.size,
                    percentage: 100,
                },
            });
        }
        return files;
    }
    async loadPartImageCounts() {
        const parts = this.uploadButtonsTargets.map(({ dataset }) => ({
            manufacturer: dataset.manufacturer,
            partNumber: dataset.partNumber,
        }));
        this.countsValue = await $.ajax({
            type: 'POST',
            url: this.countUrlValue,
            data: JSON.stringify(parts),
            dataType: 'json',
            contentType: 'application/json',
        });
        return this.countsValue;
    }
}
