import { Controller } from 'stimulus';
import '../../assets/styles/cart_steps.css';
export default class default_1 extends Controller {
    static targets = ['formContainer', 'selector', 'registerButton'];
    showForm() {
        this.formContainerTarget.classList.remove('hide');
        this.selectorTarget.classList.add('hide');
    }
    hideForm() {
        this.formContainerTarget.classList.add('hide');
        this.selectorTarget.classList.remove('hide');
    }
    connect() {
        this.registerButtonTargets
            .filter((button) => button.disabled)
            .forEach((button) => {
            button.disabled = false;
        });
    }
}
