import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import ImageEditor from '@uppy/image-editor';
import '@uppy/image-editor/dist/style.css';
import Webcam from '@uppy/webcam';
import '@uppy/webcam/dist/style.css';
import XHRUpload from '@uppy/xhr-upload';
import uppyLithuanian from '../i18n/uppy-lt';
import UppyBatchDashboardPlugin from '../plugins/UppyBatchDashboardPlugin';
export const createUppyUploader = (uploadOptions) => {
    const uppy = new Uppy({ autoProceed: false, debug: process.env.NODE_ENV === 'dev' });
    uppy
        .use(Webcam, {
        id: 'Webcam',
        countdown: false,
        modes: ['picture'],
        showVideoSourceDropdown: true,
        mirror: false,
        videoConstraints: {
            width: { min: 720, ideal: 3840 },
            height: { min: 720, ideal: 2160 },
            facingMode: 'environment',
        },
        locale: uppyLithuanian,
    })
        .use(UppyBatchDashboardPlugin, {
        disablePageScrollWhenModalOpen: true,
        target: 'body',
        proudlyDisplayPoweredByUppy: false,
        showRemoveButtonAfterComplete: true,
        autoOpenFileEditor: false,
        disableStatusBar: false,
        plugins: ['Webcam'],
        inline: false,
        locale: uppyLithuanian,
        id: 'UppyBatchDashboardPlugin',
    })
        .use(ImageEditor, {
        quality: 0.9,
        target: UppyBatchDashboardPlugin,
        id: 'ImageEditor',
    })
        .use(XHRUpload, {
        limit: 4,
        bundle: false,
        method: 'POST',
        formData: true,
        fieldName: 'file',
        metaFields: [],
        id: 'XHRUpload',
        ...uploadOptions,
    })
        .on('upload-success', (file, result) => {
        uppy.setFileMeta(file.id, { id: result.body.id });
    });
    return uppy;
};
