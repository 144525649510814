const uppyLithuanian = {
    strings: {
        takePicture: 'Fotografuoti',
        allowAccessTitle: 'Prašome suteikti prieigos teises prie jūsų webcam',
        allowAccessDescription: 'Norint fotografuoti, prašome suteikti teises šiam puslapiui naudotis jūsų webcam',
        pluginNameCamera: 'Fotoaparato',
        cancel: 'Atšaukti',
        done: 'Baigta',
        back: 'Atgal',
        removeFile: 'Ištrinti failą',
        uploading: 'Įkeliama',
        complete: 'Baigta',
        addMore: 'Pridėti daugiau failų',
        addingMoreFiles: 'Pridedama daugiau failų',
        dropHint: 'Nutempkite failus čia',
        dropPasteBoth: 'Nutempkite failus čia arba įklijuokite',
        importFrom: 'Įkelti iš "%{name}"',
        dropPasteImportBoth: 'Nutempk failus čia, %{browseFiles}, %{browseFolders} arba įkelk iš:',
        dropPasteImportFiles: 'Nutempk failus čia, %{browseFiles} arba įkelk iš:',
        browseFiles: 'pasirink failus',
        myDevice: 'Iš mano kompiuterio',
    },
};
export default uppyLithuanian;
