import { Controller } from 'stimulus';
import '../css/barcode_scanner.css';
import { App } from '../Service/DecodeBarcode';
export default class default_1 extends Controller {
    static targets = ['result', 'preview', 'fileInput', 'resultContainer', 'feedbackIcon'];
    connect() {
        const self = this;
        this.fileInputTarget.addEventListener('change', function (e) {
            if (e.target instanceof HTMLInputElement && e.target.files?.length) {
                App.decode(URL.createObjectURL(e.target.files[0]), (data) => {
                    self.showScanPreview(data);
                });
            }
        });
    }
    scan(e) {
        this.fileInputTarget.click();
    }
    showScanPreview(data) {
        const code = data?.codeResult?.code ?? null;
        this.feedbackIconTarget.classList.remove('hidden');
        if (!code) {
            this.resultTarget.value = '';
            this.resultContainerTarget.classList.add('has-error');
            this.resultContainerTarget.classList.remove('has-success');
            this.resultTarget.placeholder = 'Nepavyko nuskaityti barkodo';
            this.feedbackIconTarget.classList.replace('glyphicon-ok', 'glyphicon-remove');
        }
        else {
            this.resultTarget.value = code;
            this.resultContainerTarget.classList.add('has-success');
            this.resultContainerTarget.classList.remove('has-error');
            this.resultTarget.placeholder = '';
            this.feedbackIconTarget.classList.replace('glyphicon-remove', 'glyphicon-ok');
        }
    }
}
