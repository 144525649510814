import { loadStripe } from '@stripe/stripe-js/pure';
import { Controller } from 'stimulus';
import '../css/stripe.css';
export default class default_1 extends Controller {
    static values = { apiPublicKey: String, clientSecret: String, successUrl: String };
    static targets = ['payButton', 'cardElement', 'cardError', 'spinner', 'buttonText', 'resultMessage'];
    style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#32325d',
            },
        },
        invalid: {
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            color: '#a94442',
            iconColor: '#a94442',
        },
    };
    async connect() {
        this.stripe = await loadStripe(this.apiPublicKeyValue);
        if (!this.stripe) {
            throw 'Unable to load Stripe library';
        }
        this.payButtonTarget.disabled = true;
        const elements = this.stripe.elements();
        this.card = elements.create('card', { style: this.style });
        this.card.mount(this.cardElementTarget);
        this.card.on('change', (event) => {
            this.payButtonTarget.disabled = event?.empty ?? false;
            this.cardErrorTarget.textContent = event?.error?.message ?? '';
        });
    }
    payWithCard(event) {
        event.preventDefault();
        this.showSpinnerOnPaymentSubmission();
        this.stripe?.confirmCardPayment(this.clientSecretValue, { payment_method: { card: this.card } }).then((result) => {
            if (result.error) {
                this.showCustomerErrorOnChargeFailure(result.error.message);
            }
            else {
                this.showSuccessMessageOnCompletePayment(result.paymentIntent?.id);
            }
        });
    }
    showSpinnerOnPaymentSubmission() {
        this.payButtonTarget.disabled = true;
        this.showElement(this.spinnerTarget);
        this.hideElement(this.buttonTextTarget);
    }
    hideSpinner() {
        this.payButtonTarget.disabled = false;
        this.hideElement(this.spinnerTarget);
        this.showElement(this.buttonTextTarget);
    }
    showCustomerErrorOnChargeFailure(errorMessage) {
        this.hideSpinner();
        this.cardErrorTarget.textContent = errorMessage;
        this.showElement(this.cardErrorTarget);
        setTimeout(() => {
            this.cardErrorTarget.textContent = '';
            this.hideElement(this.cardErrorTarget);
        }, 4000);
    }
    hideElement(...items) {
        items.forEach((item) => item.classList.add('hidden'));
    }
    showElement(...items) {
        items.forEach((item) => item.classList.remove('hidden'));
    }
    showSuccessMessageOnCompletePayment(paymentIntentId) {
        this.hideSpinner();
        if (process.env.NODE_ENV !== 'production') {
            this.resultMessageTarget
                .querySelector('a')
                ?.setAttribute('href', `https://dashboard.stripe.com/test/payments/${paymentIntentId}`);
        }
        this.showElement(this.resultMessageTarget);
        this.payButtonTarget.disabled = true;
        setTimeout(() => {
            location.href = this.successUrlValue;
        }, 1500);
    }
}
