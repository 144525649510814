import Quagga from '@ericblade/quagga2';
export const App = {
    decode: function (src, resultCallback) {
        const self = this, config = { ...self.state, ...{ src } };
        Quagga.decodeSingle(config, resultCallback);
    },
    state: {
        debug: true,
        inputStream: {
            size: 800,
            singleChannel: false,
        },
        locator: {
            patchSize: 'medium',
            halfSample: false,
            debug: {
                showCanvas: true,
                showPatches: true,
            },
        },
        decoder: {
            debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true,
            },
            readers: [
                {
                    format: 'ean_reader',
                    config: {
                        supplements: ['ean_8_reader', 'ean_5_reader', 'ean_2_reader'],
                    },
                },
                {
                    format: 'code_128_reader',
                    config: {
                        supplements: [],
                    },
                },
                {
                    format: 'upc_reader',
                    config: {
                        supplements: [],
                    },
                },
            ],
        },
        locate: true,
        src: null,
    },
};
export default Quagga;
